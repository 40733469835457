
import Button from "@/components/Button";
export default {
  name: "HomeCompany",
  components: {
    Button
  },
  data() {
    return {
      activeIndex: 0,
      swiperOption: {
        loop: false,
        spaceBetween: '9.76%',
        slidesPerView: 1.4,
        centeredSlides: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
            simulateTouch: false,
            centeredSlides: false,
          }
        },
        pagination: {
          el: '.company-swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.company-swiper-next',
          prevEl: '.company-swiper-prev'
        },

        on: {
          slideChange: (swiper) => {
            /*let nextIndex = swiper.realIndex;
            this.toggleActive(nextIndex)
            console.log(nextIndex)
            ///////this.$refs.howto1.changeImage(nextIndex);
            this.$refs.howto2.changeImage(nextIndex);
            this.$refs.device.changeImage(nextIndex);*/
          }
        }
      },
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.m === 'c') {
      this.showCompany()
    }
  },
  methods: {
    showCompany() {
      this.$modal.show("modal-company")
    },
  }
}
