
export default {
name: "Button",
  props: {
    mode: {
      type: String,
      default: 'black'
    },
    link: {
      type: String,
      default: ''
    },
    isBlank: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
}
