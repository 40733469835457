
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
export default {
  name: "Footer.vue",
  mounted() {
    let pagetop = this.$refs.pagetop;
    let container = document.querySelector('.container');
    gsap.to(pagetop, {
      scrollTrigger: {
        trigger: container,
        start: 'top -5%',
        onEnter: () => {
          pagetop.classList.add('show');
        },
        onLeaveBack: () => {
          pagetop.classList.remove('show');
        }
      }
    })
  },
  methods: {
    scrolltoTop() {
      this.$store.dispatch('app/scrolltoTop')
    },

  }
}
