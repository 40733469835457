
export default {
  name: "SectionTitle",
  data: function () {
    return {
      isAppear: false
    }
  },
  computed: {
    appear: function () {
      if (!this.isAppear) {
        let result = this.$store.state.app.currentScene;
        if (result === this.category) {
          this.isAppear = true
          return true;
        }
        return false
      } else {
        return true
      }
    }
  },
  props: {
    category: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: "black"
    },
    align: {
      type: String,
      default: ''
    }
  }
}
