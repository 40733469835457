import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d5a725fe&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=d5a725fe&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Keyvisual: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/Keyvisual.vue').default,SideNavi: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/SideNavi.vue').default,SectionTitle: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/SectionTitle.vue').default,PlayButton: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/PlayButton.vue').default,HomeWhatis: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/HomeWhatis.vue').default,HomeHowto: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/HomeHowto.vue').default,HomeCompany: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/HomeCompany.vue').default,Footer: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/Footer.vue').default})
