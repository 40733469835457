
export default {
  name: "Header.vue",
  data: function () {
    return {

    }
  },
  computed: {
    naviOpened: function () {
      return this.$store.getters["app/naviOpen"];
    }
  },
  methods: {
    toggleNavi() {
      this.$store.dispatch('app/toggleNavi');
    },
    closeNavi() {
      this.$store.dispatch('app/closeNavi')
    },
    linkto(to) {
      this.$store.dispatch('app/scrollTo', {
        to: to
      })
      this.closeNavi();
    }
  }
}
