
import {gsap, Quint, Cubic} from 'gsap';
export default {
  name: "Keyvisual.vue",
  data: function() {
    return {
      appear: false,
      is_cookie_selected: false,
      swiperOption: {
        loop: true,
        speed: 200,
        autoplay: {
          delay: 4000
        },
        virtualTranslate: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: false
        },
        simulateTouch: false,
        spaceBetween: 0,
        breakpoints: {
          768: {
            slidesPerView: 1
          }
        },

        on: {
          slideChange: (swiper) => {
            let nextIndex = swiper.realIndex;
            this.$refs.prod
          },
        }
      }
    }
  },
  computed: {
    acceptcookie: function() {
      if (process.client) {
        if (this.$cookies.get('cookie-accept') || this.is_cookie_selected) {
          return false;
        }
      }
      return true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      gsap.delayedCall(0.3, () => {
        //this.appearPeoples()
        this.appearProd()
      })

    })

  },
  methods: {
    openBetaModal() {
      this.$modal.show('modal-beta')
    },
    openAboutModal() {
      this.$modal.show('modal-beta2')
    },
    closeBetaModal() {
      this.$modal.hide('modal-beta')
    },
    appearPeoples: function () {
      //let p1 = this.$refs.p1;
      //let p2 = this.$refs.p2;
      let timeline = gsap.timeline()
      /*timeline.set('.p1', {
        opacity: 0,
        x: 220
      })
      timeline.set('.p2', {
        opacity: 0,
        x: -220
      });
      timeline.to([p1, p2], {
          opacity: 1,
          delay: 0.38,
          duration: 0.8,
          x: 0,
          ease: Cubic.easeInOut
        })*/
      /*timeline.to([p1, p2], {
        duration: 1,
        x: 0,
        ease: Quint.easeInOut
      });*/
    },
    appearProd: function() {
      let prods = this.$refs.prod;
      gsap.delayedCall(0.8, () => {
        this.appear = true;
      });
    },
    cookieAgree: function(is_agree) {
      if (is_agree) {
        this.$cookies.set('cookie-accept', 1);
      }
      this.is_cookie_selected = true;
    }
  }
}
