
//import GlslCanvas from 'glslCanvas/dist/GlslCanvas';
import {gsap, Cubic, Quint} from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
export default {
name: "VfxImg",
  data: function () {
    return {
      alt: '',
      id: 'vfx-canv-' + 1,
      y: 0,
      isSetup: false,
      imgsrc: this.src,
      obj: {
        x: 0,
        gray: 0
      }
    }
  },
  props: {
    src: {
      type: String
    },
    srcset: {
      type: Array,
      default() {
        return []
      }
    },
    frag: {
      type: String,
      //default: 'void main(){\ngl_FragColor = vec4(1.0);\n}'
    },
    vert: {
      type: String,
    }
  },
  created() {
    if (process.client) {
      this.GlslCanvas = require("glslCanvas/dist/GlslCanvas")
    }
  },
  mounted: function() {
    this.alt = this.$attrs.alt || '';
    //this.glslCanv.setUniform('u_duration', 0.0)
    this.setup()
  },
  methods: {
    inViewport: function () {
      let wrap = this.$refs.vfxImageWrap;
      if (wrap) {
        let rect = wrap.getBoundingClientRect()
        this.y = rect.top;
        if ((wrap.clientHeight - this.y) > 0 && (wrap.clientHeight + this.y) > 0) {
          return true
        }
      }
      return false;
    },
    setup: function() {
      /*this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'app/windowScrolled') {
          if (this.inViewport()) {
            this.glslCanv.setUniform('u_duration', this.obj.x)
          } else {
            this.glslCanv.setUniform('u_duration', 0.0)
          }
        }
      });*/
      //this.tick()

      let img = this.$refs.vfxImage;
      let canvas = this.$refs.canv;
      let _self = this;
      function firstLoad() {
        if (_self.GlslCanvas) {
          _self.glslCanv = new _self.GlslCanvas(_self.$refs.canv);
        }

        canvas.width = Math.round(img.clientWidth * 2);
        canvas.height = Math.round(img.clientHeight * 2);
        img.removeEventListener('load', firstLoad);
        _self.setupSecond()
        _self.setupGsap();
      }

      img.addEventListener('load', firstLoad)
    },

    setupSecond: function () {
      let img = this.$refs.vfxImage;
      img.addEventListener('load', () => {
        this.animate(0.2);
      })
    },

    setupGsap: function () {
      this.isSetup = true;
      let wrap = this.$refs.vfxImageWrap;
      gsap.to(wrap, {
        duration: 1,
        scrollTrigger: {
          trigger: wrap,
          start: 'top bottom',
          onEnter: () => {
            this.animate(0.4);
          }
        }
      })
    },

    changeImage(index) {
      let img = this.$refs.vfxImage;
      if (this.srcset.length !== 0) {
        this.imgsrc = this.srcset[index];
        img.src = this.srcset[index];
      }
    },


    animate: function(baseDuration=1) {
      if (!this.isSetup) {
        return;
      }
      this.glslCanv.setUniform('u_tex0', this.imgsrc)
      let canv = this.$refs.canv;
      if (!canv || !canv.clientWidth) {
        return
      }
      this.obj = {
        x: canv.clientWidth / 10,
        u_gray: 1
      }
      this.glslCanv.setUniform('u_duration', this.obj.x)
      this.glslCanv.setUniform('u_gray', this.obj.u_gray)
      gsap.to(this.obj, {
        x: 0,
        duration: baseDuration + 0.5,
        delay: 0,//.14,
        ease: Cubic.easeOut,
        onUpdate: () => {
          this.glslCanv.setUniform('u_duration', this.obj.x)
        }
      })
      gsap.to(this.obj, {
        u_gray: 0,
        duration: baseDuration + 0.6,
        ease: Quint.easeIn,
        onUpdate: () => {
          this.glslCanv.setUniform('u_gray', this.obj.u_gray)
        }
      })
    }
  }
}
