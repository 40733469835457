
import {gsap, Cubic} from 'gsap';
import {DrawSVGPlugin} from 'gsap/dist/DrawSVGPlugin'
export default {

  name: "Logo",
  created() {
    gsap.registerPlugin(DrawSVGPlugin)
  },
  mounted: function () {
    this.$nextTick(() => {
      this.drawLogo()
    });
  },
  methods: {
    drawLogo() {
      let outline = this.$refs.logoOutline;
      let outlineWrap = this.$refs.logoOutlineWrap;
      let logoFill = this.$refs.logoFill;
      gsap.set(outline, {
        drawSVG: 0,
        opacity: 1
      });
      gsap.to(outline,  {
        duration: 1.6,
        drawSVG: '100%',
        ease: Cubic.easeInOut,
        delay: 0.4,
        onComplete: () => {
          gsap.to(outlineWrap, {
            duration: 0.8,
            opacity: 0,
            ease: Cubic.easeOut
          });

          gsap.to(logoFill, {
            duration: 0.8,
            opacity: 1,
            ease: Cubic.easeOut
          });
        }
      })
    }
  }

}
