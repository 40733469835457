import { render, staticRenderFns } from "./HomeHowto.vue?vue&type=template&id=4bf02f00&scoped=true&"
import script from "./HomeHowto.vue?vue&type=script&lang=js&"
export * from "./HomeHowto.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf02f00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/Users/masayukitahara/github-private/iNSYNC-Site/components/SectionTitle.vue').default})
