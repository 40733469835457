
import _each from 'lodash/each';
import VfxImg from "~/components/effects/VfxImg";
export default {
  name: "HomeWhatis",
  components: {
    VfxImg
  },
  data() {
    return {
      activeIndex: 1,
      swiperOption: {
        loop: true,
        spaceBetween: 30,
        pagination: {
          el: '.what-swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.what-swiper-next',
          prevEl: '.what-swiper-prev'
        },
        on: {
          slideChange: (swiper) => {
            let nextIndex = swiper.realIndex;
            /////this.toggleActive(nextIndex);
            this.$refs.what.changeImage(nextIndex);
            ////this.$refs['what' + this.activeIndex].animate(0)
          },
          init: (swiper) => {
            /*let wrap = this.$refs['whatwrap' + swiper.realIndex];
            if (wrap) {
              wrap.classList.add('active');
            }*/
          }
        }
      }
    }
  },

  methods: {
    toggleActive(activeIndex) {
      this.activeIndex = activeIndex;
    },
    getSrcSet(path, num, ext='jpg') {
      let result = []
      for(let i = 1; i <= num; ++i) {
        result.push(path + i + '.' + ext);
      }
      return result;
    }
  }
}
