export const state = () => ({
  naviOpen: false,
  scrollto: '',
  currentScene: 'keyvisual',
  scrollY: 0,
  scrolltotop: 0,
});

export const mutations = {
  toggleNavi(state, payload) {
    state.naviOpen = payload.naviOpen;
  },
  closeNavi(state) {
    state.naviOpen = false;
  },
  scrollTo(state, payload) {
    state.scrollto = payload.to
  },
  scrolltoTop(state) {
    state.scrolltotop += 1;
  },
  scrollEnter(state, payload) {
    state.currentScene = payload.enter
  },
  windowScrolled(state, payload) {
    state.scrollY = ((window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop);
  }
}

export const actions = {
  /**
   *
   * */
  toggleNavi({ commit, state }, payload) {
    let _nav = !state.naviOpen
    commit('toggleNavi', {
      "naviOpen": _nav
    });
  },
  closeNavi({commit, state}, payload) {
    commit('closeNavi');
  },

  scrollTo({commit, state}, payload) {
    commit('scrollTo', {
      to: payload.to
    })
  },
  scrolltoTop({commit, state}, payload) {
    commit('scrolltoTop')
  },

  scrollEnter({commit, state}, payload) {
    commit('scrollEnter', {
      enter: payload.enter
    })
  },


  windowScrolled({commit, state}, payload) {
    commit('windowScrolled', payload)
  }


}

export const getters = {
  naviOpen(state) {
    return state.naviOpen;
  },

}
