
export default {
name: "PlayButton",
  props: {
    video: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'm'
    }
  }
}
