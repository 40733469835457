
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import _ from 'lodash';

export default {
  name: "SideNavi.vue",
  data: function () {
    return {
      current: 'keyvisual',
      appear: false
    }
  },
  created() {
    gsap.registerPlugin(ScrollTrigger)
  },
  mounted() {
    this.setup()
    gsap.delayedCall(2.4, () => {
      this.appear = true;
    })
  },
  methods: {
    setup: function() {
      let leftNavi = this.$refs.naviLeft
      let rightNavi = this.$refs.naviRight
      let sections = document.querySelectorAll('.js-section');
      _.each(sections, (section, index) => {
        let id = section.id;
        ScrollTrigger.create({
          trigger: section,
          start: "top center",
          end: "bottom center",
          onEnter: ({progress, direction, isActive}) => {
            this.current = id;
            this.$store.dispatch("app/scrollEnter", {
              enter: id.replace('#', '')
            });
          },
          onEnterBack: ({progress, direction, isActive}) => {
            this.current = id;

          }
        });
      })
    },

    updateCategory: function(id) {

    },
    scrollto: function (to) {
      this.$store.dispatch('app/scrollTo', {to: to})
    }
  }
}
