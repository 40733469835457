
import VfxImg from "~/components/effects/VfxImg";
export default {
  name: "HomeHowto.vue",
  components: {
    VfxImg
  },
  data() {
    return {
      activeIndex: 0,
      swiperOption: {
        loop: true,
        spaceBetween: 30,
        pagination: {
          el: '.howto-swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.howto-swiper-next',
          prevEl: '.howto-swiper-prev'
        },

        on: {
          slideChange: (swiper) => {
            let nextIndex = swiper.realIndex;
            this.toggleActive(nextIndex)
            ///////this.$refs.howto1.changeImage(nextIndex);
            this.$refs.howto2.changeImage(nextIndex);
            this.$refs.device.changeImage(nextIndex);
          },
          init: (swiper) => {

          }
        }
      }
    }
  },
  methods: {
    toggleActive(activeIndex) {
      this.activeIndex = activeIndex;
    },
    getSrcSet(path, num, ext='jpg') {
      let result = []
      for(let i = 1; i <= num; ++i) {
        result.push(path + i + '.' + ext);
      }
      return result;
    }
  }
}
