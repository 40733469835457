
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import Logo from "~/components/Logo";
import Button from "~/components/Button";
import Keyvisual from "~/components/Keyvisual";
import SectionTitle from "~/components/SectionTitle";
import PlayButton from "~/components/PlayButton";
import HomeWhatis from "~/components/HomeWhatis";
import HomeHowto from "@/components/HomeHowto";
import HomeCompany from "@/components/HomeCompany";
import SideNavi from "~/components/SideNavi";
import {gsap, Quint, Cubic} from 'gsap'
import ScrollTo from 'gsap/dist/ScrollToPlugin';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import _ from 'lodash';
import VfxImg from "~/components/effects/VfxImg";
export default {
  components: {
    VfxImg,
    Button,
    Header,
    Footer,
    Logo,
    Keyvisual,
    PlayButton,
    SectionTitle,
    HomeWhatis,
    HomeHowto,
    HomeCompany,
    SideNavi,
  },
  data() {
    return {
      playerVars: {
        autoplay: 1
      }
    }
  },
  created() {
    gsap.registerPlugin(ScrollTo)
    gsap.registerPlugin(ScrollTrigger)
  },
  mounted() {
    this.setup();
    this.setupParallax();
    this.setupLazy();
    this.setupScrollTo();

  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  },
  methods: {
    setup: function () {
      window.addEventListener('scroll', (event) => {
        this.$store.dispatch('app/windowScrolled', {
          event: event
        });
      });

      /*this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'app/scrollTo') {
          console.log(state.app.scrollto)
          /!*gsap.to(window, {
            duration: 0.8,
            scrollTo: state.app.scrollto,
            ease: Quint.easeInOut
          });*!/
        } else if (mutation.type === "app/scrollEnter") {

        }

      });*/
      this.hideVideoModal();
      this.hideCompany();
    },
    setupScrollTo: function () {
      this.unsubscribe = this.$store.subscribe(((mutation, state) => {
        if (mutation.type === 'app/scrollTo') {
          let target = document.querySelector(state.app.scrollto)
          if (!target) return
          gsap.to(window, {
            duration: 0.8,
            delay: 0.1,
            scrollTo: state.app.scrollto,
            ease: Quint.easeInOut
          });
        } else if (mutation.type === 'app/scrolltoTop') {
          gsap.to(window, {
            duration: 0.8,
            scrollTo: 0,
            ease: Quint.easeInOut
          });
        }
      }))

      if (this.$route.hash) {
        gsap.delayedCall(0.3, () => {
          this.$store.dispatch('app/scrollTo', {
            to: this.$route.hash
          })
        })
      }
    },
    setupParallax: function () {
      let parallaxes = document.querySelectorAll('.js-parallax');
      _.each(parallaxes, (elm, index) => {
        let __target = elm.getAttribute('data-trigger') || elm;
        let __start = elm.getAttribute('data-start') || 'top 100%';
        let __end = elm.getAttribute('data-end') || 'top -100%';
        let __from = elm.getAttribute('data-from') || '100%';
        let __to = elm.getAttribute('data-to') || '-100%';

        gsap.fromTo(elm, {
            y: __from,
        },
        {
          duration: 1,

          scrollTrigger: {
            trigger: __target,
            start: __start,
            end: __end,
            scrub: 0.8,
          },
          y: __to,
          //ease: Cubic.easeOut
        })
      })
    },
    setupLazy() {
      let lazys = document.querySelectorAll('.js-lazyclass');
      _.each(lazys, (elm, index) => {
        let __target = elm.getAttribute('data-trigger') || elm;
        let __start = elm.getAttribute('data-start') || 'top 60%';

        gsap.to(elm,
          {
            duration: 1,
            scrollTrigger: {
              trigger: __target,
              start: __start,
              //scrub: 0.8,
              onEnter: () => {
                elm.classList.add('appear')
              },
              onLeaveBack: () => {
                elm.classList.remove('appear')
              }
            },
            //ease: Cubic.easeOut
          })
      })
    },

    hideCompany() {
      this.$modal.hide("modal-company")
    },

    showNews() {
      this.$modal.show("modal-news")
    },

    hideNews() {
      this.$modal.hide("modal-news")
    },

    openVideoModal() {
      this.$modal.show('modal-video')
      if (this.$refs.youtube) {
        this.$refs.youtube.player.playVideo()
      }
    },
    hideVideoModal() {
      this.$modal.hideAll()
      this.$modal.hide("modal-video")
    }
  }
}
